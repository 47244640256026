@layer components {
  .primary-title-text {
    @apply font-title font-black text-[4.375rem] lg:text-[5.5rem];
    font-stretch: condensed;
  }
  .body-title-text {
    @apply font-title font-black leading-none text-5xl sm:text-[4rem];
    font-stretch: condensed;
  }
  .big-body-title-text {
    @apply font-title font-black leading-none text-4xl sm:text-[3.75rem];
    font-stretch: condensed;
  }
  .banner-title-text {
    @apply font-title font-bold leading-none text-4xl sm:text-[3.125rem];
    font-stretch: condensed;
  }
  .list-title-text {
    @apply font-title font-black leading-none text-[2.5rem];
    font-stretch: condensed;
  }
  .secondary-body-title {
    @apply font-title font-bold leading-none text-[2.5rem];
    font-stretch: condensed;
  }
  .quad-title-text {
    @apply font-title font-black leading-none text-2xl sm:text-4xl;
  }
  .block-title-text {
    @apply font-body font-extrabold text-[2rem];
    font-stretch: normal;
    line-height: 0.81;
  }
  .card-title-text {
    @apply font-title font-bold leading-none text-[1.75rem] sm:text-[2rem];
    font-stretch: condensed;
  }
  .date-text {
    @apply font-title font-black leading-none text-[1.75rem];
    font-stretch: condensed;
  }
  .tertary-body-title {
    @apply font-title font-bold leading-none text-2xl lg:text-[1.75rem];
    font-stretch: condensed;
    line-height: 0.82;
  }
  .notification-text {
    @apply font-body text-xl lg:text-2xl font-bold;
    font-stretch: normal;
  }
  .list-pretitle-text {
    @apply font-title text-xl sm:text-2xl font-bold;
    font-stretch: condensed;
  }
  .menu-text {
    @apply font-title text-[1.75rem] lg:text-[1.375rem] font-bold;
    font-stretch: condensed;
    letter-spacing: 3.3px;
  }
  .info-text {
    @apply font-body text-xl font-bold leading-snug;
    font-stretch: normal;
  }
  .body-text {
    @apply font-body text-lg sm:text-xl font-normal leading-snug;
  }
  .link-text {
    @apply font-body text-lg font-extrabold;
    font-stretch: normal;
  }
  .sub-menu-text {
    @apply font-title text-2xl lg:text-lg font-bold;
    font-stretch: condensed;
    letter-spacing: 2.7px;
  }
  .bold-small-body-text {
    @apply font-body text-lg font-bold;
    font-stretch: normal;
  }
  .button-text {
    @apply font-body text-lg font-semibold;
    font-stretch: normal;
  }
  .small-body-text {
    @apply font-body text-lg font-normal leading-tight;
    font-stretch: normal;
  }
  .card-button-text {
    @apply font-body text-base font-extrabold leading-relaxed;
    font-stretch: normal;
  }
  .pager-text {
    @apply font-body text-base font-bold leading-relaxed;
    font-stretch: normal;
    letter-spacing: 0.48px;
  }
  .subtitle-text {
    @apply font-body text-base font-semibold leading-none;
    font-stretch: normal;
    letter-spacing: 0.8px;
  }
}
