.hero-banner {
  grid-template-rows: max-content max-content max-content;
  grid-template-columns: 100%;
  @screen sm {
    grid-template-rows: 400px max-content max-content;
  }
  @screen lg {
    grid-template-rows: 1fr max-content max-content max-content 1fr;
    grid-template-columns: min(38.75rem, 70%) 1fr;
  }

  .media-wrapper {
    @screen lg {
      clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 135px 100%);
    }
  }
}