.text-format {
  
  h2 {
    @apply mb-6 md:mb-10 body-title-text text-dark;

    &:last-child {
      @appy mb-0;
    }

    & strong {
      @apply font-bold;
    }
  }

  h3 {
    @apply mb-6 md:mb-8 block-title-text text-dark;
  }

  h4 {
    @apply mb-6 tertary-body-title text-dark;
  }

  p {
    @apply mb-6;

    &.mb-2 {
      @apply mb-2;
    }
  }

  &>*:last-child {
    @apply mb-0;
  }

  &.small {

    strong {
      @apply font-semibold;
    }
  }

  a:not(.button) {
    @apply text-tomato;
    background-image: linear-gradient(90deg, currentColor 0%, currentColor 100%);
    background-size: 0% 2px;
    background-position: right bottom;
    background-repeat: no-repeat;
    transition: background-size 300ms ease;

    &:hover {
      background-position: left bottom;
      background-size: 100% 2px;
    }
  }

  ul, ol {
    margin: 1em 1.5em;
    @apply leading-normal my-8 mx-[1.5em];
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  &.text-white {
    h2, h3 {
      @apply text-white;
    }
  }
}