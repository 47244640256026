/**
 * app.css
 *
 * The entry point for the css.
 *
 */

/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 */

@import "tailwindcss/base";
/**
 * This injects any component classes registered by plugins.
 *
 */

@import 'tailwindcss/components';
/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */

 /**
 * Include vendor css.
 *
 */
/* purgecss start ignore */
@import '../../node_modules/basiclightbox/dist/basicLightbox.min.css';
@import '../../node_modules/glightbox/dist/css/glightbox.min.css';
@import '../../node_modules/swiper/swiper-bundle.css';
/* purgecss end ignore */

/* purgecss start ignore */
@import './utility/*.pcss';

/* component */
@import './components/*.pcss';

/* page builder block styles */
@import './components/blocks/*.pcss';
/* purgecss end ignore */

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */

@import 'tailwindcss/utilities';
/**
 * Include styles for individual pages
 *
 */
 /* purgecss start ignore */
@import './pages/*.pcss';
/* purgecss end ignore */

/**
 * Include custom vendor css.
 *
 */
/* purgecss start ignore */
@import './vendors/*.pcss';
/* purgecss end ignore */