:root {
  --plyr-color-main: theme('colors.tomato');
}

html {
  @apply h-full;
}

.content-main {
  transition: padding-top 150ms ease;
}

@layer components {
  .default-wrapper {
    @apply max-w-8xl px-5 sm:px-8 w-full mx-auto;
  }

  .simple-wrapper {
    @apply max-w-8xl px-5 sm:px-8 xl:px-[7.125rem] w-full mx-auto;
  }

  .momentum-scroll {
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }

  .underline-anim {
    background-image: linear-gradient(90deg, currentColor 0%, currentColor 100%);
    background-size: 0% 2px;
    background-position: right bottom;
    background-repeat: no-repeat;
    transition: background-size 300ms ease;

    &:hover {
      background-position: left bottom;
      background-size: 100% 2px;
    }
  }
}

.basicLightbox {
  @apply bg-white bg-opacity-90;
}