@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@400;600;700;800&display=swap');

@font-face {
    font-family: 'DIN OT Condensed';
    src: url('../../assets/fonts/DINOT-CondBold.woff2') format('woff2'),
        url('../../assets/fonts/DINOT-CondBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'DIN OT Condensed';
    src: url('../../assets/fonts/DINOT-CondBlack.woff2') format('woff2'),
        url('../../assets/fonts/DINOT-CondBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}