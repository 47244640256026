.form-wrapper {
  .form-label {
    &.required{
      &:after {
        content: "*";
        @apply text-tomato;
      }
    }
  }

  .form-check-label {
    @apply inline-flex items-center cursor-pointer relative;

    .checkmark {
      @apply flex w-4 h-4 border transition-colors duration-150 bg-white items-center justify-center mr-2;

      svg {
        @apply opacity-0 transition-opacity duration-150;
      }
    }

    input {
      @apply absolute left-0 top-0 h-0 w-0 invisible;
    }

    &:hover {
      & .checkmark {
        
      }
    }

    input:checked ~ .checkmark {

      & svg {
        @apply opacity-100;
      }
    }
  }

  .submit-button {

    &.ff-loading {
      &:after {
        background-image: url('../../assets/loader.gif');
      }
    }
  }

  .ff-form-errors {
    @apply bg-white border border-tomato-red text-tomato-red mb-8 p-6;
  }

  .ff-form-success {
    @apply bg-white border border-fern-green text-fern-green mb-8 p-6;
  }

  .ff-has-errors {
    @apply border-tomato-red;
  }

  .ff-errors {
    @apply text-tomato-red;
  }
}